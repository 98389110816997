import { createInertiaApp } from '@inertiajs/svelte'
createInertiaApp({
    resolve: name => {
        const page = import(`@views/${name}.svelte`)
        return page
    },
    setup({ App, props }) {
        let el = document.getElementById('app')
        new App({ target: el, props })
    },
})